<!-- 管理已存在课题组成员对话框 -->
<template>
    <a-modal width="900px" :visible="visible" title="课题组成员管理" @cancel="handleCancel" :footer="null">
        <div>
            <a-form-model :model="formData" :rules="rules" ref="formRef">
                <a-form-model-item label="课题组" :labelCol="{ span: 2 }" :wrapperCol="{ span: 20 }">
                    <div > {{ record.name }} ( {{ record.code }} ) </div>
                </a-form-model-item>

                <div>
                    <a-button type="primary" @click="addMember" :disabled="isLoading"> + 添加课题组成员 </a-button>
                </div>
                <a-form-model-item prop="list">
                    <div class="table-container">
                        <a-table size="small" :columns="tableColumns" :data-source="formData.list" rowKey="userId" :pagination="false" :loading="isLoading">
                            <template slot="index" slot-scope="text, record, index">
                                {{ index+ 1 }}

                            </template>
                          <!--   <template slot="avatar" slot-scope="text, record, index">
                                <div>
                                    <DefaultAvatar :url="record.avatar" :name="record.userName"></DefaultAvatar>
                                </div>
                            </template> -->
                            <template slot="identityType" slot-scope="text, record, index">
                                <div>
                                    {{  researchTeamIdentityMap[record.identityType]  }}
                                </div>
                            </template>
                            <template slot="hasManagementResearchGroup" slot-scope="text, record, index">
                                <div class="">
                                    <a-tooltip v-if="record.identityType == '1'" title="无法操作PI默认权限">
                                        <a-switch :checked="record.hasManagementResearchGroup" :disabled="true"></a-switch>
                                    </a-tooltip>
                                    <a-switch v-else :checked="record.hasManagementResearchGroup" @click="val => store.state.userInfo.userId === record.userId && record.hasManagementResearchGroup ?  handleOperatingSelfManagement(record,index) : handleOperatingUserManage(record,index)" :loading="operatingUserManageUserIdList.indexOf(record.userId) != -1"></a-switch>
                                </div>
                            </template>
                            
                            <template slot="hasCreateProject" slot-scope="text, record, index">
                                <div class="">
                                    <a-tooltip v-if="record.identityType == '1'" title="无法操作PI默认权限">
                                        <a-switch :checked="record.hasCreateProject" :disabled="true"></a-switch>
                                    </a-tooltip>
                                    <a-switch v-else :checked="record.hasCreateProject" @click="val => handleOperateCreateProject(record,val)" :loading="operatingCreateGroupUserIdList.indexOf(record.userId) != -1"></a-switch>
                                </div>
                            </template>
                            <template slot="operation" slot-scope="text, record, index">
                                <a-button type="link" @click="handleDelete(record)" v-if="record.identityType != '1' && record.userId != store.state.userInfo.userId" style="color: #ff4d4f;">移除</a-button>
                                <a-button type="link" v-if="record.identityType != '1' && record.userId == store.state.userInfo.userId" style="color: #ff4d4f;" @click="handleQuitResearchTeam(record)"> 退出 </a-button>
                                <a-button type="link" @click="handleEditInfo(record)" v-if="record.identityType != '1'"> 身份权限 </a-button>
                            </template>
                        </a-table>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </div>
        <!-- <template slot="footer">
            <div style="display: flex; justify-content: center;" class="footer">
                <a-button type="primary" :disabled="isBtnDisabled" :loading="isSubmitting" @click="handleConfirm"> 确定 </a-button>
                <a-button @click="handleCancel" :loading="isSubmitting"> 取消 </a-button>
            </div>
        </template> -->
        <AddMemberForExitGroupModal :visible.sync="addMemberModalVisible" :memberList="formData.list" @onAddMember="onAddMember" :record="record"> </AddMemberForExitGroupModal>
        <EditUserInfoModal :visible.sync="editUserInfoModalVisible" :record="selectedUser" :researchGroupId="record.id" @onEditUserInfo="onEditUserInfo"></EditUserInfoModal>
    </a-modal>
</template>

<script>
import { computed, h, ref, watch } from 'vue-demi';
import DefaultAvatar from '../../../../components/defaultAvatar.vue';
import AddMemberForExitGroupModal from './addMemberForExitGroupModal.vue';
import { researchTeamIdentityList, researchTeamIdentityMap  } from '@/utils/enums.js';
import { getResearchGroupUserList,  deleteResearchGroupUser, editGroupUser } from '@/api/researchGroup';
import { message, Modal } from 'ant-design-vue';
import EditUserInfoModal from './editUserInfoModal.vue';
import store from '../../../../store';
const tableColumns = [
    {
        title: '序号',
        scopedSlots: { customRender: "index" }
    },
   /*  {
        title: '头像',
        dataIndex: 'avatar',
        scopedSlots: { customRender: "avatar" }
    }, */
    {
        title: '成员名称',
        dataIndex: 'userName',
        scopedSlots: { customRender: "name" }
    },
    {
        title: '手机',
        dataIndex: 'phone'
    },
    {
        title: '邮箱',
        dataIndex: 'email'
    },
    {
        title: '身份',
        scopedSlots: { customRender: "identityType" }
    },
    {
        title: '课题组管理',
        scopedSlots: { customRender: "hasManagementResearchGroup" }
    },
    {
        title: '新建项目',
        scopedSlots: { customRender: "hasCreateProject" }
    },
    {
        title: '操作',
        scopedSlots: { customRender: "operation" }
    }
]
export default {
    props: {
        visible: {
            default: false
        },
        record: {
            default: {},
            required: true
        }
    },
    components: {
    DefaultAvatar,
    AddMemberForExitGroupModal,
    EditUserInfoModal
},
    setup(props, context) {
        const formData = ref({
            name: '',
            list: []
        })


         // 获取课题组成员列表
         const getUserList = async () => {
            isLoading.value = true;
            const res = await getResearchGroupUserList({
                researchGroupId: props.record.id
            });
            if(res.success) {
                formData.value.list = res.data.detail
            }
            isLoading.value = false;
        }

        watch(() => props.visible, (val) => {
            if(val) {
                formData.value.name ='';
                formData.value.list = [
                  
                ]
                formRef.value && formRef.value.resetFields();
                operatingCreateGroupUserIdList.value = [];
                operatingUserManageUserIdList.value = [];
                getUserList();
            }
            
        })

        const isLoading = ref(true);    // 是否正在加载信息

        const isSubmitting = ref(false);        // 是否正在提交

        const formRef = ref();     // 表格的ref

        // 取消
        const handleCancel = () => {
            context.emit('update:visible', false);
        }

        // 校验规则
        const rules = ref({
            list: [
                {
                    validator: (_rule, value, callback) => {
                        if(value.length === 0) {
                            callback(new Error('至少要有一位PI成员'))
                        }
                        // 获取
                        const piList = value.filter(user => user.identityType == '1');
                        if(piList.length == 0) {
                            callback(new Error('至少要有一位PI成员'))
                        }
                        else if(piList.length > 1) {
                            callback(new Error('只能有一位PI成员'))
                        }
                        callback();
                    }
                }
            ]
        })


        // 增加课题组成员对话框可见变量
        const addMemberModalVisible = ref(false);

        // 点击添加课题组成员
        const addMember = () => {
            addMemberModalVisible.value = true;
            /* if(formData.value.list[formData.value.list.length -1].index !== 0 ) {
                formData.value.list.push({
                    id: '',
                    index: 0,
                })
            } */
        }

        // 添加成员回调
        const onAddMember = () => {
           getUserList();
        } 

        // 身份选择
        const handleIdentitySelect = (val, record, index) => {
            const user = formData.value.list[index]
            user.identityType = val;
            if(val !== '3') {
                user.hasCreateProject = true;
                user.hasManagementResearchGroup = true;
            }
            else {
                user.hasCreateProject = false;
                user.hasManagementResearchGroup = false;
            }
        }

        // 是否有课题组
        const  handleHasManageResearchGroupChange = (checked, record, index) => {
            formData.value.list[index].hasManagementResearchGroup = checked;
        }

        // 是否有新建项目的权限
        const handleHasCreateProjectChange = (checked, record, index) => {
            formData.value.list[index].hasCreateProject = checked
        }

        // 确认新教课题组
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async(valid) => {
                if(valid) {
                    isSubmitting.value = true;
                    const res = await addResearchGroup({
                        name: formData.value.name,
                        list: formData.value.list.map(user => {
                            return {
                                userId: user.id,
                                identityType: parseInt(user.identityType),
                                hasCreateProject: user.hasCreateProject,
                                hasManagementResearchGroup: user.hasManagementResearchGroup
                            }
                        })
                    });
                    if(res.success) { 
                        Modal.success({
                            content: `创建课题组${formData.value.name}成功`
                        })
                        context.emit('update:visible', false);
                        context.emit('onAddResearchTeam')
                    }
                    isSubmitting.value = false;
                }
            })
        }

        // 点击删除成员
        const handleDelete = (record) => {
            Modal.confirm({
                title: '提示',
                content: h('span', {  }, [
                    h('span', { }, '是否确认将用户'),
                    h('span', { style: { color: '#1890ff' } }, `${record.userName} ( ${researchTeamIdentityMap[record.identityType]} )`),
                    h('span', { }, '移除课题组？')
                ]),
                asyncClose: true,
                onOk: async () => {
                    const res = await deleteResearchGroupUser({
                        groupId: props.record.id,
                        userId: record.userId
                    });
                    if(res.success) {
                        message.success(`已将用户${record.userName} ( ${researchTeamIdentityMap[record.identityType]} )移除课题组`);
                        getUserList();
                    }
                }
            })
        }

        // 点击退出课题组
        const handleQuitResearchTeam = (record) => {
            Modal.confirm({
                title: '提示',
                content: h('span', {  }, [
                    h('span', { }, '是否确认'),
                    h('span', { }, '退出课题组？')
                ]),
                asyncClose: true,
                onOk: async () => {
                    const res = await deleteResearchGroupUser({
                        groupId: props.record.id,
                        userId: record.userId
                    });
                    if(res.success) {
                        message.success(`已退出课题组`);
                        context.emit('update:visible', false);
                        context.emit('onEditMember');
                    }
                }
            })
        }

        /*****************************************                正在设置/取消               ***************************************/
        const operatingCreateGroupUserIdList = ref([]); // 正在进行新建项目权限操作的用户列表
        const handleOperateCreateProject = async (record, index) => {
            if(operatingCreateGroupUserIdList.value.indexOf(record.userId) != -1) {
                return ;
            }
            operatingCreateGroupUserIdList.value.push(record.userId);
            const res = await editGroupUser({
                researchGroupId: props.record.id,
                userId: record.userId,
                hasCreateProject: !record.hasCreateProject
            });
            if(res.success) {
                if(record.hasCreateProject) {
                    message.info({
                        content:  h('span', { }, [
                            h('span', {}, '已关闭用户'),
                            h('span', { style: { color: '#1890ff' }}, `${record.userName}(${researchTeamIdentityMap[record.identityType]})`),
                            '新建项目的权限'
                        ])
                    });
                }
                else {
                    message.success({
                        content:  h('span', { }, [
                            h('span', {}, '已开启用户'),
                            h('span', { style: { color: '#1890ff' }}, `${record.userName}(${researchTeamIdentityMap[record.identityType]})`),
                            '新建项目的权限'
                        ])
                    });
                }
                const user =  formData.value.list.filter(user => user.userId == record.userId)[0];
                user.hasCreateProject = !user.hasCreateProject
            }
            operatingCreateGroupUserIdList.value = operatingCreateGroupUserIdList.value.filter(id => id != record.userId);
        }
        /*******************************************************************************************************************************************/

        /**************************************             正在进行课题组管理权限操作                  **********************************************/
        const operatingUserManageUserIdList = ref([]); // 正在进行课题组管理权限操作的用户id列表
        
        // 如果是关闭自己的权限
        const handleOperatingSelfManagement = (record,index) => {
            Modal.confirm({
                content: '关闭自己管理权限后，无法再对用户进行管理，是否确认关闭？',
                onOk: () => {
                    handleOperatingUserManage(record,index, true);
                }
            })
        }

        // 设置用户管理权限
        const handleOperatingUserManage = async (record, index, refrsh = false) => {
            if(operatingUserManageUserIdList.value.indexOf(record.userId) != -1) {
                return ;
            }
            operatingUserManageUserIdList.value.push(record.userId);
            const res = await editGroupUser({
                researchGroupId: props.record.id,
                userId: record.userId,
                hasManagementResearchGroup: !record.hasManagementResearchGroup
            });
            if(res.success) {
                if(record.hasManagementResearchGroup) {
                    message.info({
                        content:  h('span', { }, [
                            h('span', {}, '已关闭用户'),
                            h('span', { style: { color: '#1890ff' }}, `${record.userName}(${researchTeamIdentityMap[record.identityType]})`),
                            '管理项目的权限'
                        ])
                    });
                }
                else {
                    message.success({
                        content:  h('span', { }, [
                            h('span', {}, '已开启用户'),
                            h('span', { style: { color: '#1890ff' }}, `${record.userName}(${researchTeamIdentityMap[record.identityType]})`),
                            '管理项目的权限'
                        ])
                    });
                    /* if(record.userId === store.state.userInfo.userId) {
                        context.emit('update:visible', false);
                        context.emit('onEditMember');
                    } */
                }
                const user =  formData.value.list.filter(user => user.userId == record.userId)[0];
                user.hasManagementResearchGroup = !user.hasManagementResearchGroup
                if(refrsh) {
                    context.emit('update:visible', false);
                        context.emit('onEditMember');
                }
            }
            operatingUserManageUserIdList.value = operatingCreateGroupUserIdList.value.filter(id => id != record.userId);
        }
        /******************************************************************************************************************************************/

        /**********************************************                       身份权限                        **************************************/
        const selectedUser = ref({ }) // 当前选中的用户
        // 身份权限点击事件
        const handleEditInfo = (record) => {
            selectedUser.value = record;
            editUserInfoModalVisible.value  = true;
        }
        // 身份权限对话框可见变量
        const editUserInfoModalVisible = ref(false);

        // 编辑成功回调
        const onEditUserInfo = (record) => {
            const user = formData.value.list.filter(user => user.userId == selectedUser.value.userId)[0];
            
            user.identityType = record.identityType;
            user.hasCreateProject = record.hasCreateProject;
            user.hasManagementResearchGroup = record.hasManagementResearchGroup;
            if(selectedUser.value.userId === store.state.userInfo.userId) {
                context.emit('update:visible', false);
                context.emit('onEditMember');
            }
        }

        /******************************************************************************************************************************************/
        return {
            formData,
            formRef,
            isLoading,
            isSubmitting,
            tableColumns,
           // handleUserSearch,
           // searchUserList,
            //filterOption,
           // handleChange,
           // userList,
            handleCancel,
            rules,
            addMember,
           /*  list,
            handleUserSelectChange, */
            addMemberModalVisible,
            onAddMember,
            researchTeamIdentityList,
            handleIdentitySelect,
            handleHasManageResearchGroupChange,
            handleHasCreateProjectChange,
            handleConfirm,
            researchTeamIdentityMap,
            handleDelete,
            operatingCreateGroupUserIdList,
            handleOperateCreateProject,
            handleOperatingUserManage,
            operatingUserManageUserIdList,
            selectedUser,
            handleEditInfo,
            editUserInfoModalVisible,
            onEditUserInfo,
            store,
            handleQuitResearchTeam,
            handleOperatingSelfManagement
        }
    }
}
</script>

<style lang="less" scoped>
.table-container {
    margin-top: 16px;

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}
</style>