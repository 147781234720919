<template>
    <a-modal :visible.sync="visible" title="编辑课题组信息" :width="480" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在加载">
            <a-form-model :model="formData" :rules="rules" ref="formRef">
                <a-form-model-item label="课题组名称：" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }"  prop="name">
                    <a-input v-model="formData.name" placeholder="请输入"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div style="display: flex; justify-content: center;">
                <a-button type="primary" @click="handleConfirm" :disabled="!isChange" :loading="isSubmitting"> 确定 </a-button>
                <a-button @click="handleCancel"> 取消 </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, ref, watch } from 'vue-demi';
import { editGroupName } from '../../../../api/researchGroup';
export default {
    props: {
        visible: {
            default: false
        },
        name: {
            default: ''
        },
        record: {
            default: () => {}
        }
    },
    setup(props, context) {
        // 列表数据
        const formData = ref({
            name: ''
        })

        // 表单校验规则
        const rules = ref({
            name: [
                {
                    required: true,
                    whitespace: true,
                    message: '请输入课题组名称'
                }
            ]
        })

        // 表单实例
        const formRef = ref()

        watch(() => props.visible, async (val) => {
            if(val) {
                formRef.value && formRef.value.resetFields();
                formData.value.name = props.record.name;
            }
        })

        // 指是否发生更改
        const isChange = computed(() => {
            return formData.value.name != props.record.name; 
        })

        const handleCancel = () => {
            context.emit('update:visible', false);
        }

        const loading = ref(false); // 加载中

        const isSubmitting = ref(false);     // 是否正在提交

        // 确认提交修改
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async (valid) => {
                if(valid) {
                    isSubmitting.value = true;
                    const res = await editGroupName({
                        id: props.record.id,
                        name: formData.value.name
                    })
                    if(res.success) {
                        message.success('修改成功');
                        context.emit('update:visible', false);
                        context.emit('onEditInfo');
                    }
                    isSubmitting.value = false;
                }
            })
        }
        return {
            handleCancel,
            loading,
            rules,
            formRef,
            isSubmitting,
            handleConfirm,
            formData,
            isChange
        }
    }
}
</script>