<!-- 我的课题组页面 -->
<template>
    <div class="research-team-ctn">
        <div class="research-team-header">
            我的课题组
        </div>
        <div class="research-team-search-content">
            <div class="search-option">
                <span class="search-label">课题组名称：</span>
                <div style="width: 280px;">
                    <a-input placeholder="请输入" v-model="searchValue"></a-input>
                </div>
            </div>
            <div class="search-option">
                <span class="search-label">
                    状态：
                </span>
                <div style="width: 220px;">
                    <a-select style="width: 100%;" v-model="teamStatu" placeholder="启用/停用" allowClear>
                       <!--  <a-select-option v-for="(option, index) in [{ label: '启用', value: true }, { label: '停用', value: false }]" :value="option.value" :label="option.label">
                        </a-select-option> -->
                        <a-select-option :key="true">
                            启用
                        </a-select-option>
                        <a-select-option :key="false">
                            停用
                        </a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="search-btns">
                <a-button type="primary" @click="handleSearch">
                    查询
                </a-button>
                <a-button class="reset-btn" @click="handleReset">
                    重置
                </a-button>
            </div>
        </div>
        <div class="research-team-operation">
            <a-button v-if="userInfo.isCreateResearchGroup" type="primary" @click="handleAddNewReasearchTeamBtnClick">
                + 新增课题组
            </a-button>
            <a-tooltip v-else placement="top" title="没有PI权限，请联系管理员">
                <a-button :disabled="true" type="primary">
                    + 新增课题组
                </a-button>
            </a-tooltip>
        </div>
        <div class="table-container" >
            <a-table :scroll="{ y: tableHeight, x: 800 }" size="small" :bordered="false" :data-source="list" :loading="isTableLoading" :columns="getTableColumns" :pagination="false" rowKey="id">
               <!--  <template slot="teamName" slot-scope="text, record, index">
                    123
                </template> -->
              <!--   <template slot="code" slot-scope="text, record, index" >
                    123
                </template> -->
                <template slot="member" slot-scope="text, record, index">
                    <div>
                        <span>导师：</span>
                        <span> {{  record.teachers.length > 0 ? record.teachers.join('、') : '-' }}</span>
                    </div>
                    <div>
                        <span>成员：</span>
                        <span> {{   record.members.length > 0 ? record.members.join('、') : '-'  }}</span>
                    </div>
                </template>
                <template slot="statu" slot-scope="text, record, index" >
                    <div v-if="record.editable">
                        <a-switch :loading="changeStauIndex.indexOf(record.id) != -1" :checked="record.status" @click="handleSwitchClick(record, index)"></a-switch>
                    </div>
                    <div v-else>
                        <a-tooltip content="无操作权限">
                            <a-switch :checked="record.status" :disabled="true"></a-switch>
                        </a-tooltip>
                    </div>
                </template>
                <template slot="operation" slot-scope="text, record, index">
                    <div>
                        <div v-if="record.editable">
                            <a-button type="link" @click="handleEditReasearchGroupClick(record)"> 编辑 </a-button>
                        </div>
                        <div v-if="record.editable">
                            <a-button type="link" @click="handleEditMemberClick(record)"> 成员管理 </a-button>
                        </div>
                        <div >
                            <a-button type="link" @click="goToDetail(record)"> 查看详情 </a-button>
                        </div>
                    </div>
                </template>
            </a-table>
        </div>
        <div class="default-pagination" style="padding: 32px 16px 30px 16px;">
			<a-pagination :disabled="changeStauIndex.length > 0" hideOnSinglePage @change="handlePageChange" v-model="current"
					:pageSize.sync="pageSize" :total="total" :pageSizeOptions="['15', '35', '50']" showSizeChanger @showSizeChange="handleSizeChange"/>
		</div>
        <AddNewResearchTeamModal :visible.sync="addNewResearchTeamModalVisible" @onAddResearchTeam="onAddResearchTeam"></AddNewResearchTeamModal>
        <EditResearchTeamInfoModal :visible.sync="editResearchTeamInfoModalVisible" :name="edittingResearchGroup.name" @onEditInfo="onEditInfo" :record="edittingResearchGroup"></EditResearchTeamInfoModal>
        <EditResearchGroupMemberModal :visible.sync="editResearchGroupMemberModalVisible" :record="selectedResearchGroup" @onEditMember="onEditMember"></EditResearchGroupMemberModal>
    </div>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, onMounted, ref } from 'vue-demi';
import store from '../../../store';
import AddNewResearchTeamModal from './researchTeamComponents/addNewResearchTeamModal.vue';
const tableHeight = document.documentElement.clientHeight - 460;
import { getResearchGroup, setReaseachGroupStatu } from '@/api/researchGroup.js';
import EditResearchTeamInfoModal from './researchTeamComponents/editResearchTeamInfoModal.vue';
import EditResearchGroupMemberModal from './researchTeamComponents/editResearchGroupMemberModal.vue';
const allTableColumn = [
    {
        title:'课题组名称',
        dataIndex: 'name',
        scopedSlots: { customRender: "teamName" }
    },
    {
        title: '课题组编号',
        dataIndex: 'code',
    },
    {
        title: '课题组PI',
        dataIndex: 'piName'
    },
    {
        title: '课题组成员',
        dataIndex: '',
        scopedSlots: { customRender: "member" }
    },
    {
        title: '启用/停用',
        scopedSlots: { customRender: "statu" }
    },
    {
        title: '操作',
        scopedSlots: { customRender: "operation" }
    }

]
export default {
    components: {
    AddNewResearchTeamModal,
    EditResearchTeamInfoModal,
    EditResearchGroupMemberModal
},
    props: {

    },
    setup(props, context) {
        const { $route, $router } = context.root;

        const searchValue = ref('');    // 搜索内容

        const list = ref([]);       // 行数
        const current = ref(1);     // 当前页数
        const pageSize = ref(15);   // 每页数量
        const total = ref(0);       // 全部数量
        const teamStatu = ref(undefined);      // 当前状态

        // 重置搜索条件
        const handleReset = () => {
            isTableLoading.value = true;
            teamStatu.value = undefined;
            searchValue.value = '';
            current.value = 1;
            getList();
        }

        // 点击查询
        const handleSearch = () => {
            isTableLoading.value = true;
            getList();
        }

        // 列表是否正在加载
        const isTableLoading = ref(true);


        // 获取列表
        const getList = async () => {
            changeStauIndex.value = [];
            isTableLoading.value = true;
            const res = await getResearchGroup({
                pageNo: current.value,
                pageSize: pageSize.value,
                searchBeginTime: undefined,
                searchBeginTime: undefined,
                status: teamStatu.value,
                name: searchValue.value
            })
            if(res.success) {
                total.value = res.data.totalRows;
                list.value = res.data.rows;
            }
           /*  for(let i = 0; i < 20; i++) {
                list.value.push({
                    key: i,
                    statu: true
                })
            } */
            isTableLoading.value = false;

        }

        onMounted(() => {
            getList();
        })

        // 根据权限获取对应的列表行
        const getTableColumns = computed(() => {
            return allTableColumn;
        })

        // 页面参数改变事件
        const handlePageChange = () => {
            getList();
        }

        // 每页数量改变
        const handleSizeChange = (newCurrent, newPageSize) => {
            current.value = 1;
            pageSize.value =newPageSize;
            getList()
        }
        onMounted(() => {
            handleReset()
        })

        // 正在启用/禁用的课题组index
        const changeStauIndex = ref([]);

        // switch点击
        const handleSwitchClick = async (record, index) => {
            (changeStauIndex.value.indexOf(record.id)  === -1) &&  changeStauIndex.value.push(record.id);
            const res = await setReaseachGroupStatu({
                id: record.id,
                status: !record.status
            })
            if(res.success) {
                //changeStauIndex.value = changeStauIndex.value.filter(item => item.id !== record.id);
                changeStauIndex.value.splice(changeStauIndex.value.indexOf(record.id),1);
                if(!record.status) {
                    message.success(`成功启用课题组${record.name}(${record.code})`)
                }
                else {
                    message.info(`已停用课题组${record.name}(${record.code})`)
                }
                const listRes =  list.value.filter(item => item.id == record.id);
                listRes.forEach(item => {
                    item.status = !record.status
                })
                
            }
            /* setTimeout(() => {
                list.value[index].statu = !list.value[index].statu;
                changeStauIndex.value = changeStauIndex.value.filter(item => item != index);
                message.success('成功')
            }, 3000) */
        }

        // 用户信息
        const userInfo = computed(() => {
          /*   console.log(store) */
            return store.state.userInfo;
        })

        // 新增课题组对话框可见变量
        const addNewResearchTeamModalVisible = ref(false);

        // 新建课题组点击
        const handleAddNewReasearchTeamBtnClick = () => {
            addNewResearchTeamModalVisible.value = true;
        }

        // 新增课题组后的回调
        const onAddResearchTeam = () => {
            getList();
        }

        // 选中的课题组
        const selectedResearchGroup = ref({});


        /*********************************                编辑课题组信息                ********************************/
        // 正在编辑的课题组
        const edittingResearchGroup = ref({})

        // 编辑课题组信息对话框可见变量
        const editResearchTeamInfoModalVisible = ref(false);

        // 点击编辑课题组
        const handleEditReasearchGroupClick = (record) => {
            edittingResearchGroup.value = record;
            editResearchTeamInfoModalVisible.value = true;

        }
        // 编辑后的回调
        const onEditInfo = () => {
            edittingResearchGroup.value = {}
            getList();
        }
        /*************************************************************************************************************/
        /*********************************                编辑课题组信息                ********************************/
        // 编辑成员点击
        const handleEditMemberClick = (record) => {
            selectedResearchGroup.value = record
            editResearchGroupMemberModalVisible.value = true;
        }

        // 编辑成员对话框可见变量
        const editResearchGroupMemberModalVisible = ref(false);

        // 编辑成员后的回调
        const onEditMember = () => {
            getList()
        }
        /*************************************************************************************************************/

        // 查看详情点击
        const goToDetail = (record) => {
            const { $router } = context.root;
            const href =  $router.resolve({
                name: 'researchGroup',
                query: {
                    id: record.id
                }
            });
            window.open(href.href, '_blank')
        }
        return {
            searchValue,
            list,
            current,
            pageSize,
            total,
            handleReset,
            isTableLoading,
            getTableColumns,
            tableHeight,
            handlePageChange,
            handleSwitchClick,
            changeStauIndex,
            teamStatu,
            userInfo,
            addNewResearchTeamModalVisible,
            handleAddNewReasearchTeamBtnClick,
            onAddResearchTeam,
            edittingResearchGroup,
            handleEditReasearchGroupClick,
            editResearchTeamInfoModalVisible,
            onEditInfo,
            selectedResearchGroup,
            handleEditMemberClick,
            editResearchGroupMemberModalVisible,
            onEditMember,
            handleSearch,
            goToDetail,
            handleSizeChange
        }
    }
}
</script>

<style lang="less" scoped>
.research-team-ctn {
    color: rgba(0, 0, 0, 0.65);
}

.research-team-header {
    margin-left: 16px;
    padding: 16px 16px 16px 0px;
    border-bottom: 1px solid #EBEBEB;
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);

}

.research-team-search-content {
    display: flex;
    align-items: center;
    padding: 16px 16px;
    .search-option {
        display: flex; 
        align-items: center;
    }

    .search-option+.search-option {
        margin-left: 20px;
        display: flex;
        align-items: center;
    }

    .search-btns {
        display: flex;
        margin-left: 32px;
        .ant-btn +.ant-btn {
            margin-left: 16px;
        }

        .reset-btn {
            background: #eeeeee;
        }
    }

}
.research-team-operation {
    padding-left: 16px;
    text-align: left;
}

.table-container {
    margin-top: 16px;
    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}
</style>