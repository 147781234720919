<!-- 新建 -->
<template>
    <a-modal width="900px" :visible="visible" title="新增课题组" @cancel="handleCancel">
        <div>
            <a-form-model :model="formData" :rules="rules" ref="formRef">
                <a-form-model-item label="课题组名称" :labelCol="{ span: 3 }" :wrapperCol="{ span: 10 }" prop="name">
                    <a-input v-model="formData.name" placeholder="请输入"></a-input>
                </a-form-model-item>
                <div>
                    <a-button type="primary" @click="addMember"> + 添加课题组成员 </a-button>
                </div>
                <a-form-model-item prop="list">
                    <div class="table-container">
                        <a-table size="small" :columns="tableColumns" :data-source="formData.list" rowKey="userId" :pagination="false">
                            <!-- <template slot="index" slot-scope="text, record, index">
                                {{ record.index }}
                            </template> -->
                           <!--  <template slot="name" slot-scope="text, record, index">
                                <div v-if="record.id === ''">
                                    <a-select show-search v-model="formData.list[index].id"  @search="handleUserSearch"
                                        @change="handleUserSelectChange" option-label-prop="label">
                                        <a-select-option v-for="(user, userIndex) in searchUserList" :value="user.id" :key="user.id" :label="user.name">
                                            <div style="display: flex; align-items: center;flex-wrap: wrap;">
                                                <DefaultAvatar :name="user.name"></DefaultAvatar>
                                                <span style="word-break: break-all;">
                                                    {{ user.name  }}(180222222222)
                                                </span>
                                            </div>
                                        </a-select-option>
                                    </a-select>
                                </div>
                                <div v-else>
                                    {{ record.name }}
                                </div>
                            </template> -->
                            <template slot="index" slot-scope="text, record, index">
                                {{ index+ 1 }}

                            </template>
                            <template slot="identityType" slot-scope="text, record, index">
                                <div>
                                    <a-select style="width: 100%;" :value="record.identityType" @change=" val => handleIdentitySelect(val, record, index)">
                                        <a-select-option v-for="(option, optionIndex) in researchTeamIdentityList" :key="option.value">
                                            {{  option.label  }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </template>
                            <template slot="hasManagementResearchGroup" slot-scope="text, record, index">
                                <div class="">
                                    <a-tooltip v-if="record.identityType == 1" title="PI默认包含管理权限">
                                        <a-switch :checked="record.hasManagementResearchGroup" :disabled="true"></a-switch>
                                    </a-tooltip>
                                    <a-switch v-else :checked="record.hasManagementResearchGroup"  @change="checked => handleHasManageResearchGroupChange(checked, record, index)"></a-switch>
                                </div>
                            </template>
                            
                            <template slot="hasCreateProject" slot-scope="text, record, index">
                                <div class="">
                                    <a-tooltip v-if="record.identityType == 1" title="PI默认包含创建权限">
                                        <a-switch :checked="record.hasCreateProject" :disabled="true"></a-switch>
                                    </a-tooltip>
                                    <a-switch v-else :checked="record.hasCreateProject"  @change="checked => handleHasCreateProjectChange(checked, record, index)"></a-switch>
                                </div>
                            </template>
                            <template slot="operation" slot-scope="text, record, index">
                                <a-button type="link" @click="formData.list.splice(index, 1)">删除</a-button>
                            </template>
                        </a-table>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </div>
        <template slot="footer">
            <div style="display: flex; justify-content: center;" class="footer">
                <a-button type="primary" :disabled="isBtnDisabled" :loading="isSubmitting" @click="handleConfirm"> 确定 </a-button>
                <a-button @click="handleCancel" :loading="isSubmitting"> 取消 </a-button>
            </div>
        </template>
        <AddMemberModal :visible.sync="addMemberModalVisible" :memberList="formData.list" @onAddMember="onAddMember"> </AddMemberModal>
    </a-modal>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import DefaultAvatar from '../../../../components/defaultAvatar.vue';
import store from '../../../../store';
import AddMemberModal from './addMemberModal.vue';
import { researchTeamIdentityList, researchTeamIdentityMap  } from '@/utils/enums.js';
import { addResearchGroup } from '@/api/researchGroup';
import { Modal } from 'ant-design-vue';
const tableColumns = [
    {
        title: '序号',
        scopedSlots: { customRender: "index" }
    },
    {
        title: '成员名称',
        dataIndex: 'name',

        scopedSlots: { customRender: "name" }
    },
    {
        title: '身份',
        scopedSlots: { customRender: "identityType" }
    },
    {
        title: '课题组管理',
        scopedSlots: { customRender: "hasManagementResearchGroup" }
    },
    {
        title: '新建项目',
        scopedSlots: { customRender: "hasCreateProject" }
    },
    {
        title: '操作',
        scopedSlots: { customRender: "operation" }
    }
]
export default {
    props: {
        visible: {
            default: false
        }
    },
    components: {
        DefaultAvatar,
        AddMemberModal
    },
    setup(props, context) {
        const formData = ref({
            name: '',
            list: []
        })

        const list = ref([]);

        const userInfo = computed(() => {
            return store.state.userInfo
        })

        watch(() => props.visible, (val) => {
            if(val) {
                formData.value.name ='';
                formData.value.list = [
                    {
                        userId: userInfo.value.userId,
                        name: userInfo.value.name,
                        identityType: '1', 
                        hasCreateProject: true,
                        hasManagementResearchGroup: true
                    }
                ]
                formRef.value && formRef.value.resetFields();
            }
            
        })

        const isLoading = ref(true);    // 是否正在加载信息

        const isSubmitting = ref(false);        // 是否正在提交

        const formRef = ref();     // 表格的ref
/* 
        const searchUserList = ref([
            {
                id: 2,
                name: 'Damon',
            },
            {
                id: 3,
                name: 'Able'
            }
        ]);     // 搜索结果

        // 已经选择得用户Id列表
        const selectedUserIdList = computed(() => {
            formData.value.list.map(user => user.id);
        })

        // 过滤掉已选择的用户
        const filterOption = computed(() => {
            const searchUserIds = searchUserList.value.map(user => user.id);
            return searchUserList.value.filter(user => {
                return user.id
            })
        })

        // 搜索成员
        const handleUserSearch = async () => {
        }

        // 更改
        const handleChange = () => {

        }

        // 用户列表
        const userList = ref([]);
 */
        // 取消
        const handleCancel = () => {
            context.emit('update:visible', false);
        }

        // 校验规则
        const rules = ref({
            name: [
                {
                    required: true,
                    message: '请输入'
                },
                {
                    max: 50,
                    message: '不能超过50个字符'
                }
            ],
            list: [
                {
                    validator: (_rule, value, callback) => {
                        if(value.length === 0) {
                            callback(new Error('至少要有一位PI成员'))
                        }
                        // 获取
                        const piList = value.filter(user => user.identityType == '1');
                        if(piList.length == 0) {
                            callback(new Error('至少要有一位PI成员'))
                        }
                        else if(piList.length > 1) {
                            callback(new Error('只能有一位PI成员'))
                        }
                        callback();
                    }
                }
            ]
        })

        // 页脚
        const isBtnDisabled = computed(() => {
            return formData.value.list.length === 0 || !formData.value.name;
        })

        // 增加课题组成员对话框可见变量
        const addMemberModalVisible = ref(false);

        // 点击添加课题组成员
        const addMember = () => {
            addMemberModalVisible.value = true;
            /* if(formData.value.list[formData.value.list.length -1].index !== 0 ) {
                formData.value.list.push({
                    id: '',
                    index: 0,
                })
            } */
        }

       /*  // 改变选择
        const handleUserSelectChange = (val) => {
            const searchUser = searchUserList.value.filter(user => {
                return user.id === val;
            })

            const user = formData.value.list[formData.value.list.length - 1];
            user.name = searchUser[0].name;
            user.id = searchUser[0].id;
            user.index = undefined

        } */
        // 添加成员回调
        const onAddMember = (addMemberList) => {
            const resList = addMemberList.map(user => {
                return {
                    ...user,
                    identityType: '3',    // 默认成员
                    hasCreateProject: false,
                    hasManagementResearchGroup: false
                }
            })
            formData.value.list.splice(formData.value.list.length - 1, 0, ...resList);
        } 

        // 身份选择
        const handleIdentitySelect = (val, record, index) => {
            const user = formData.value.list[index]
            user.identityType = val;
            if(val !== '3') {
                user.hasCreateProject = true;
                user.hasManagementResearchGroup = true;
            }
            else {
                user.hasCreateProject = false;
                user.hasManagementResearchGroup = false;
            }
        }

        // 是否有课题组管理权限
        const  handleHasManageResearchGroupChange = (checked, record, index) => {
            formData.value.list[index].hasManagementResearchGroup = checked;
        }

        // 是否有新建项目的权限
        const handleHasCreateProjectChange = (checked, record, index) => {
            formData.value.list[index].hasCreateProject = checked
        }

        // 确认新教课题组
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async(valid) => {
                if(valid) {
                    isSubmitting.value = true;
                    const res = await addResearchGroup({
                        name: formData.value.name,
                        list: formData.value.list.map(user => {
                            return {
                                userId: user.userId,
                                identityType: parseInt(user.identityType),
                                hasCreateProject: user.hasCreateProject,
                                hasManagementResearchGroup: user.hasManagementResearchGroup
                            }
                        })
                    });
                    if(res.success) { 
                        Modal.success({
                            content: `创建课题组${formData.value.name}成功`
                        })
                        context.emit('update:visible', false);
                        context.emit('onAddResearchTeam')
                    }
                    isSubmitting.value = false;
                }
            })
        }
        return {
            formData,
            formRef,
            isLoading,
            isSubmitting,
            tableColumns,
           // handleUserSearch,
           // searchUserList,
            //filterOption,
           // handleChange,
           // userList,
            handleCancel,
            rules,
            isBtnDisabled,
            addMember,
           /*  list,
            handleUserSelectChange, */
            addMemberModalVisible,
            onAddMember,
            researchTeamIdentityList,
            handleIdentitySelect,
            handleHasManageResearchGroupChange,
            handleHasCreateProjectChange,
            handleConfirm,
            
        }
    }
}
</script>

<style lang="less" scoped>
.table-container {
    margin-top: 16px;

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}
</style>